// Importing necessary types and models from the "models" module.
import Cookies from "js-cookie";
import { domainNames } from "../assets/examplesData";
import {
    AskRequest,
    AskResponse,
    ChatRequest,
    ChatThread,
    ChatThreadResponse,
    userChatsResponse,
    ImageRequest,
    MenuItemResponse,
    UsecaseParamsType,
    LegalChat,
    LegalChatReponse,
    BrazilJurisdictionsRequest,
    ReactToMessageRequest,
    HistoryResponse
} from "./models";
import { isExternalLink } from "../utils";
// import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";

/**
 * Makes an API call to ask a question.
 *
 * @param {AskRequest} options - The options for the Ask API call.
 * @returns {Promise<AskResponse>} A Promise that resolves to the Ask API response.
 */
export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch("/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            session_id: options.session_id,
            current_question: options.current_prompt,
            isNewChatThread: options.isNewChatThread,
            threadUrl: options.threadUrl,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                presence_penalty: options.overrides?.presence_penalty,
                frequency_penalty: options.overrides?.frequency_penalty,
                top_p: options.overrides?.top_p,
                max_tokens: options.overrides?.max_token,
                stop: options.overrides?.stop
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

/**
 * Sends a POST request with the provided options.
 * @param {ChatRequest} options - The options for the request.
 * @param {boolean} pure_mode - If true, the request will be sent to the "/pure" endpoint.
 * @param {boolean} pure_plus_mode - If true, the request will be sent to the "pureplus" endpoint.
 * @returns {Promise<AskResponse>} The response from the API.
 * @throws Will throw an error if the response status is greater than 299 or not OK.
 */
export async function chatApi(
    options: ChatRequest,
    pure_plus_o_mode: boolean = false,
    pure_plus_mode: boolean = false,
    apiEndPoint: string = ""
): Promise<AskResponse> {
    let input_url = pure_plus_mode ? "pureplus" : pure_plus_o_mode ? "/purepluso" : apiEndPoint ? apiEndPoint : "/chat";

    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            session_id: options.session_id,
            current_question: options.current_prompt,
            isNewChatThread: options.isNewChatThread,
            threadUrl: options.threadUrl,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                presence_penalty: options.overrides?.presence_penalty,
                frequency_penalty: options.overrides?.frequency_penalty,
                top_p: options.overrides?.top_p,
                max_tokens: options.overrides?.max_token,
                stop: options.overrides?.stop
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
/**
 * Sends a POST request to the "/bing" endpoint with the provided options.
 * @param {ChatRequest} options - The options for the request.
 * @param {boolean} pure_mode - If true, the request will be sent to the "/pure" endpoint.
 * @param {boolean} pure_plus_mode - If true, the request will be sent to the "pureplus" endpoint.
 * @returns {Promise<AskResponse>} The response from the API.
 * @throws Will throw an error if the response status is greater than 299 or not OK.
 */
export async function bingChatApi(options: ChatRequest, pure_mode: boolean = false, pure_plus_mode: boolean = false): Promise<AskResponse> {
    let input_url = "/bing";

    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            session_id: options.session_id,
            current_question: options.current_prompt,
            isNewChatThread: options.isNewChatThread,
            conversationStyle: options.conversationStyle,
            threadUrl: options.threadUrl,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                presence_penalty: options.overrides?.presence_penalty,
                frequency_penalty: options.overrides?.frequency_penalty,
                top_p: options.overrides?.top_p,
                max_tokens: options.overrides?.max_token,
                stop: options.overrides?.stop
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

/**
 * Sends a POST request to the "/audio" endpoint with the provided form data.
 * @param {any} formData - The form data for the request.
 * @returns {Promise<any>} The response from the API.
 * @throws Will throw an error if the response status is greater than 299 or not OK.
 */
export async function audioApi(formData: any): Promise<any> {
    let input_url = "/audio";

    const response = await fetch(input_url, {
        method: "POST",
        body: formData
    });

    const parsedResponse: any = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
/**
 * Sends a POST request to the "/talk/{session_id}" endpoint with the provided options.
 * The request body includes the chat history, approach, session ID, current question, content,
 * new chat thread status, conversation style, thread URL, talk options, and override options.
 *
 * @param {ChatRequest} options - The options for the request. This includes:
 * - history: The chat history.
 * - approach: The approach used for the chat.
 * - session_id: The session ID.
 * - current_question: The current question in the chat.
 * - content: The content of the chat.
 * - isNewChatThread: Whether this is a new chat thread.
 * - conversationStyle: The style of the conversation.
 * - threadUrl: The URL of the thread.
 * - talk: An object containing talk options such as oaModel, cogIndex, storage, fileName, and conversationStyle.
 * - overrides: An object containing override options such as semanticRanker, semanticCaptions, top, temperature, promptTemplate, promptTemplatePrefix, promptTemplateSuffix, excludeCategory, suggestFollowupQuestions, presence_penalty, frequency_penalty, top_p, max_token, and stop.
 *
 * @returns {Promise<AskResponse>} The response from the API. This includes the status, message, and data.
 * @throws Will redirect to "/login" if the response status is 403.
 * @throws Will throw an error if the response status is greater than 299 or not OK.
 */
export async function talkChatApi(options: ChatRequest): Promise<AskResponse> {
    let input_url = "/talk/" + options.session_id;

    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            session_id: options.session_id,
            current_question: options.current_prompt,
            content: options.content,
            isNewChatThread: options.isNewChatThread,
            conversationStyle: options.conversationStyle,
            threadUrl: options.threadUrl,
            talk: {
                oaModel: options.talk?.oaModel,
                cogIndex: options.talk?.cogIndex,
                storage: options.talk?.storage,
                fileName: options.talk?.fileName,
                conversationStyle: options.talk?.conversationStyle
            },
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                presence_penalty: options.overrides?.presence_penalty,
                frequency_penalty: options.overrides?.frequency_penalty,
                top_p: options.overrides?.top_p,
                max_tokens: options.overrides?.max_token,
                stop: options.overrides?.stop
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

/**
 * Sends a request to the usecase API.
 *
 * @param options - The chat request options.
 * @param name - The name of the usecase.
 * @returns A promise that resolves to the response from the API.
 * @throws An error if the response status is not within the success range (200-299) or if the response is not ok.
 */
export async function usecaseApi(options: ChatRequest, name: string): Promise<AskResponse> {
    let input_url = "/usecase/" + name;

    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            session_id: options.session_id,
            current_question: options.current_prompt,
            isNewChatThread: options.isNewChatThread,
            threadUrl: options.threadUrl,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                max_tokens: options.overrides?.max_token,
                stop: options.overrides?.stop,
                presence_penalty: options.overrides?.presence_penalty,
                frequency_penalty: options.overrides?.frequency_penalty,
                top_p: options.overrides?.top_p,
                deploymentName: options.overrides?.deploymentName,
                pastMessagesToInclude: options.overrides?.pastMessagesToInclude
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
/**
 * Sends a GET request to the "/params/{name}" endpoint .
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 *
 * @param {string} name - The name to append to the "/params/" string to form the input_url for the fetch request.
 * @returns {Promise<UsecaseParamsType>} - The parsed response from the fetch request.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function usecaseParamApi
 * @export
 */
export async function usecaseParamApi(name: string): Promise<UsecaseParamsType> {
    let input_url = "/params/" + name;

    const response = await fetch(input_url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: UsecaseParamsType = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn = Cookies.get("isUserLoggedIn");
        if (isUserLoggedIn == "False" || isUserLoggedIn == undefined) {
            Cookies.set("isUserLoggedIn", "False");
        }
        window.location.replace(`${location.origin}/#/login`);
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

/**
 * Sends a POST request to either the "/dalle3" or "dalle" endpoint, depending on the `version` parameter,
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 *
 * @param {ImageRequest} options - The options for the image request, including prompt, approach, session_id, isVariant, and overrides.
 * @param {string} [version="dalle"] - The version to determine the endpoint for the fetch request. If "dalle3", the endpoint is "/dalle3"; otherwise, it's "dalle".
 * @returns {Promise<AskResponse>} - The parsed response from the fetch request.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function imageApi
 * @export
 */
export async function imageApi(options: ImageRequest, version: string = "dalle"): Promise<AskResponse> {
    let input_url = version === "dalle3" ? "/dalle3" : "dalle";
    const response = await fetch(input_url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            prompt: options.prompt,
            approach: options.approach,
            session_id: options.session_id,
            isVariant: options.isVariant,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

/**
 * Sends a GET request to the "/menu_items" endpoint
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 * If an error occurs during the fetch request, it is logged to the console and a default MenuItemResponse object is returned.
 *
 * @returns {Promise<MenuItemResponse>} - The parsed response from the fetch request, or a default MenuItemResponse object if an error occurs.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function UserMenuApi
 * @export
 */
export async function UserMenuApi(): Promise<MenuItemResponse> {
    let input_url = "/menu_items";
    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/login`);
            }
        }
        const parsedResponse: MenuItemResponse = await response.json();

        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return { menus: [], threads: [], error: "error" };
    }
}
export async function UserHistoryApi(chatMode: string): Promise<ChatThread[]> {
    let input_url = `/get_chat_threads/${chatMode}`;
    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/login`);
            }
        }
        const parsedResponse: ChatThread[] = await response.json();

        if (response.status > 299 || !response.ok) {
            throw Error(`Unknown error with status ${response.status}`);
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return [];
    }
}

/**
 * Sends a GET request to the "/get_threads" endpoint
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 * If an error occurs during the fetch request, it is logged to the console and a default ChatThreadResponse object is returned.
 *
 * @returns {Promise<ChatThreadResponse>} - The parsed response from the fetch request, or a default ChatThreadResponse object if an error occurs.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function UserThreadsApi
 * @export
 */
export async function UserThreadsApi(): Promise<ChatThreadResponse> {
    let input_url = "/get_threads";
    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const parsedResponse: ChatThreadResponse = await response.json();
        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/login`);
            }
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return { Threads: [], error: "error" };
    }
}

/**
 * Sends a GET request to the "/get_thread_chats/{chat_session_id}" endpoint.
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 * If an error occurs during the fetch request, it is logged to the console and a default userChatsResponse object is returned.
 *
 * @param {string} chat_session_id - The ID of the chat session to be included in the endpoint URL.
 * @param {string} chat_mode - The mode of the chat to be included in the query string.
 * @returns {Promise<userChatsResponse>} - The parsed response from the fetch request, or a default userChatsResponse object if an error occurs.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function UserThreadChatsApi
 * @export
 */
export async function UserThreadChatsApi(chat_session_id: string, chat_mode: string): Promise<userChatsResponse> {
    let input_url = "/get_thread_chats/" + chat_session_id;
    const data: Record<string, string> = {
        mode: chat_mode
    };

    const queryString = Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");

    //input_url = input_url+"?"+queryString
    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/login`);
            }
        }
        const parsedResponse: userChatsResponse = await response.json();
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return { thread_chats: [], error: "error" };
    }
}

/**
 * Sends a POST request to the "/legal_chats" endpoint with the provided data
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 * If an error occurs during the fetch request, it is logged to the console and a default object is returned.
 *
 * @param {LegalChat} data - The data to be sent in the body of the POST request.
 * @returns {Promise<any>} - The parsed response from the fetch request, or a default object if an error occurs.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function LegalChatSubmitsApi
 * @export
 */
export async function LegalChatSubmitsApi(data: LegalChat): Promise<any> {
    let input_url = "/legal_chats";

    //input_url = input_url+"?"+queryString
    try {
        const response = await fetch(input_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        const parsedResponse: any = await response.json();
        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/login`);
            }
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return { chats: [], error: "error" };
    }
}

/**
 * Sends a GET request to either the "/legal_approved_chats/all" or "/legal_chats/all" endpoint, depending on the `isApproved` parameter,
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 * If an error occurs during the fetch request, it is logged to the console and a default LegalChatReponse object is returned.
 *
 * @param {boolean} [isApproved=false] - The flag to determine the endpoint for the fetch request. If true, the endpoint is "/legal_approved_chats/all"; otherwise, it's "/legal_chats/all".
 * @returns {Promise<LegalChatReponse>} - The parsed response from the fetch request, or a default LegalChatReponse object if an error occurs.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function LegalChatsApi
 * @export
 */
export async function LegalChatsApi(isApproved = false): Promise<LegalChatReponse> {
    let input_url = isApproved ? "/legal_approved_chats/all" : "/legal_chats/all";

    //input_url = input_url+"?"+queryString
    try {
        const response = await fetch(input_url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const parsedResponse: LegalChatReponse = await response.json();
        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/login`);
            }
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return { chats: [], error: "error" };
    }
}

/**
 * Sends a POST request to the "/brazil_tax_codes" endpoint with the provided data
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 * If an error occurs during the fetch request, it is logged to the console and a default object is returned.
 *
 * @param {BrazilJurisdictionsRequest} data - The data to be sent in the body of the POST request.
 * @returns {Promise<any>} - The parsed response from the fetch request, or a default object if an error occurs.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function BrazilJurisdictionsApi
 * @export
 */
export async function BrazilJurisdictionsApi(data: BrazilJurisdictionsRequest): Promise<any> {
    let input_url = "/brazil_tax_codes";

    try {
        const response = await fetch(input_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        const parsedResponse: any = await response.json();
        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/login`);
            }
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return { rows: [], error: "error" };
    }
}

/**
 * Returns the file path for a given citation. The citation can be in various formats:
 * - If it starts with "con_", it is split by "_" and the "con_" part is removed.
 * - If it starts with "source:", the "source: " part is removed.
 * - If it starts with "http", it is returned as is.
 * - If a `talkThread` is provided, the citation is appended to "/talk/content/{talkThread}".
 * - If a `usecaseName` is provided, the citation is appended to "/uc/content/{usecaseName}".
 * - Otherwise, the citation is appended to "/content/".
 *
 * @param {string} citation - The citation for which to get the file path.
 * @param {string} [usecaseName] - The name of the use case, if applicable.
 * @param {string} [talkThread] - The name of the talk thread, if applicable.
 * @returns {string} - The file path for the citation.
 *
 * @function getCitationFilePath
 * @export
 */
export function getCitationFilePath(citation: string, usecaseName?: string, talkThread?: string, source?: string): string {
    console.log("🚀 ~ getCitationFilePath ~ getCitationFilePath:", { citation });
    if (citation.startsWith("con_")) {
        let ext = citation.split("_");
        citation = citation.replace("con_" + ext[1] + "_", "").replace(".txt", "." + ext[1]);
    }
    if (citation.startsWith("source:")) {
        let ext = citation.split(" ");
        citation = citation.replace("source: ", "");
    }
    if (isExternalLink(citation || "")) {
        // let domain = extractDomainName(citation);
        return citation;
    }
    if (talkThread) {
        return `/talk/content/${talkThread}/${citation}`;
    }
    if (usecaseName) {
        return `/uc/content/${usecaseName}/${citation}`;
    } else return `/content/${citation}`;
}

/**
 * Extracts the domain name from a given URL.
 * @param {string} url - The URL from which to extract the domain name.
 * @returns {string} - The extracted domain name.
 *
 * @function extractDomainName
 * @export
 */
export function extractDomainName(url: string): string {
    // Remove the protocol (e.g., https://) if present
    const urlWithoutProtocol = url?.split("://")?.[1] || url;

    // Split the URL by "/", take the first part, and then split by "." to get the domain name
    const parts = urlWithoutProtocol?.split("/");
    const domainNameParts = parts?.[0]?.split(".");

    // The last two parts of the domain name are usually the top-level and second-level domain
    // If there are more than two parts, consider the last two
    const domainName = domainNameParts?.slice(-2).join(".");

    return domainName;
}

/**
 * Sends a POST request to the "/reactToMessage" endpoint with the provided data and returns a Promise that resolves to any type.
 * If the response status is 403, the user is redirected to the "/login" page.
 * If the response status is greater than 299 or not OK, an error is thrown.
 * If an error occurs during the fetch request, it is logged to the console and a default object is returned.
 *
 * @param {ReactToMessageRequest} data - The data to be sent in the body of the POST request.
 * @returns {Promise<any>} - The parsed response from the fetch request, or a default object if an error occurs.
 * @throws {Error} - If the response status is greater than 299 or not OK.
 *
 * @async
 * @function ReactToMessage
 * @export
 */

export async function ReactToMessage(data: ReactToMessageRequest): Promise<any> {
    let input_url = "/reactToMessage";

    try {
        const response = await fetch(input_url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        const parsedResponse: any = await response.json();
        if (response.status === 403) {
            const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
            if (isUserLoggedIn) {
                window.location.replace(`${location.origin}/#/login`);
            } else {
                window.location.replace(`${location.origin}/login`);
            }
        }
        if (response.status > 299 || !response.ok) {
            throw Error(parsedResponse.error || "Unknown error");
        }
        return parsedResponse;
    } catch (error) {
        console.error("api.ts =====>", { error });
        return { rows: [], error: "error" };
    }
}

/**
 * Returns the chat name based on the provided pathname and chat session ID.
 * The chat name is determined by a switch statement that checks if the pathname starts with certain strings.
 * If none of the cases match, the default chat name "Chat.pdf" is returned.
 *
 * @param {string} pathname - The pathname to check.
 * @param {string | undefined} chat_session_id - The chat session ID to include in the chat name.
 * @returns {string} - The chat name.
 *
 * @function getChatName
 * @export
 */
export function getChatName(pathname: string, chat_session_id: string | undefined) {
    switch (true) {
        case pathname === "/" || pathname.startsWith("/chat"):
            return `Chat_GEAGPT_Agent_${chat_session_id}.pdf`;
        case pathname.startsWith("/qa"):
            return `Chat_Ask_A_Question_${chat_session_id}.pdf`;
        case pathname.startsWith("/purepluso"):
            return `GPT_4o_${chat_session_id}.pdf`;
        case pathname.startsWith("/pureplus"):
            return `GPT_4_Turbo_${chat_session_id}.pdf`;
        case pathname.startsWith("/pure"):
            return `Chat_ChatGPT_${chat_session_id}.pdf`;
        case pathname.startsWith("/chat"):
            return `Chat_Ask_A_Question_${chat_session_id}.pdf`;
        case pathname.startsWith("/dalle3"):
            return `Chat_Dall-E_3_${chat_session_id}.pdf`;
        case pathname.startsWith("/business_user"):
            return `Chat_Business Users_${chat_session_id}.pdf`;
        case pathname.startsWith("/usecase/legal"):
            return `Chat_Ask Legal_${chat_session_id}.pdf`;
        case pathname.startsWith("/usecase/legal"):
            return `Chat_Business Users_${chat_session_id}.pdf`;
        case pathname.startsWith("/usecase"):
            return `Chat_UseCase_${chat_session_id}.pdf`;
        case pathname.startsWith("/talk"):
            return `Chat_DocuSpeak_${chat_session_id}.pdf`;
        case pathname.startsWith("/ask"):
            return `Chat_Ask_A_Question_${chat_session_id}.pdf`;
        case pathname.startsWith("/bing"):
            return `Chat_Project_Genie_${chat_session_id}.pdf`;
        default:
            return "Chat.pdf";
    }
}

export const stringifyReqBody = (options: any) => {
    return JSON.stringify({
        question: options?.question || options?.current_prompt || "",
        history: options.history,
        approach: options.approach,
        session_id: options.session_id,
        current_question: options.current_prompt || options?.question,
        session_document_key: options.session_document_key,
        filePath: options?.filePath || options?.filepath || "",
        content: options.content,
        isNewChatThread: options.isNewChatThread,
        fewShotData: options.fewShotExample,
        conversationStyle: options.conversationStyle,
        threadUrl: options.threadUrl,
        cogIndex: "question-on-doc",
        menu_name: options?.menu_name || "",
        name: options?.name || "",
        session_filepath_document_key: options?.session_filepath_document_key || "",
        is_ImageUploaded: options?.is_ImageUploaded || false,
        talk: {
            oaModel: options.talk?.oaModel,
            cogIndex: options.talk?.cogIndex,
            storage: options.talk?.storage,
            fileName: options.talk?.fileName,
            conversationStyle: options.talk?.conversationStyle
        },
        overrides: {
            semantic_ranker: options.overrides?.semanticRanker || false,
            semantic_captions: options.overrides?.semanticCaptions || false,
            top: options.overrides?.top || "",
            temperature: options.overrides?.temperature || "",
            prompt_template: options.overrides?.promptTemplate || "",
            prompt_template_prefix: options.overrides?.promptTemplatePrefix || "",
            prompt_template_suffix: options.overrides?.promptTemplateSuffix || "",
            exclude_category: options.overrides?.excludeCategory || "",
            suggest_followup_questions: options.overrides?.suggestFollowupQuestions || false,
            presence_penalty: options.overrides?.presence_penalty || "",
            frequency_penalty: options.overrides?.frequency_penalty || "",
            top_p: options.overrides?.top_p || "",
            max_tokens: options.overrides?.max_token || "",
            stop: options.overrides?.stop || "",
            deploymentName: options.overrides?.deploymentName || "",
            pastMessagesToInclude: options.overrides?.pastMessagesToInclude || ""
        }
    });
};

export async function processApiResponse(apiResponse: any) {
    if (apiResponse.status === 403) {
        const isUserLoggedIn: boolean = Cookies.get("isUserLoggedIn") == "False" || Cookies.get("isUserLoggedIn") == undefined;
        if (isUserLoggedIn) {
            window.location.replace(`${location.origin}/#/login`);
        } else {
            window.location.replace(`${location.origin}/login`);
        }
    }
    const parsedResponse: AskResponse = await apiResponse.json();
    if (apiResponse.status > 299 || !apiResponse.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

// const bodyParams = (options: any)=>{
//     prompt: options.prompt,
//     approach: options.approach,
//     session_id: options.session_id,
//     isVariant: options.isVariant,
//     question: options.question,
//             current_question: options.current_prompt,
//             isNewChatThread: options.isNewChatThread,
//             threadUrl: options.threadUrl,
//             history: options.history,
//             conversationStyle: options.conversationStyle,
//             content: options.content,
//             conversationStyle: options.conversationStyle,
//             prompt: options.prompt,
//             isVariant: options.isVariant,
//             overrides: {
//                 semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory,
//                 suggest_followup_questions: options.overrides?.suggestFollowupQuestions
//                 semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory,
//                 suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
//                 presence_penalty: options.overrides?.presence_penalty,
//                 frequency_penalty: options.overrides?.frequency_penalty,
//                 top_p: options.overrides?.top_p,
//                 max_tokens: options.overrides?.max_token,
//                 stop: options.overrides?.stop,
//                 deploymentName: options.overrides?.deploymentName,
//                 pastMessagesToInclude: options.overrides?.pastMessagesToInclude
//                 semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory,
//                 suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
//                 presence_penalty: options.overrides?.presence_penalty,
//                 frequency_penalty: options.overrides?.frequency_penalty,
//                 top_p: options.overrides?.top_p,
//                 max_tokens: options.overrides?.max_token,
//                 stop: options.overrides?.stop
//                 semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory,
//                 suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
//                 presence_penalty: options.overrides?.presence_penalty,
//                 frequency_penalty: options.overrides?.frequency_penalty,
//                 top_p: options.overrides?.top_p,
//                 max_tokens: options.overrides?.max_token,
//                 stop: options.overrides?.stop
//         semantic_ranker: options.overrides?.semanticRanker,
//         semantic_captions: options.overrides?.semanticCaptions,
//         top: options.overrides?.top,
//         temperature: options.overrides?.temperature,
//         prompt_template: options.overrides?.promptTemplate,
//         prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//         prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//         exclude_category: options.overrides?.excludeCategory,
//         suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
//         presence_penalty: options.overrides?.presence_penalty,
//         frequency_penalty: options.overrides?.frequency_penalty,
//         top_p: options.overrides?.top_p,
//         max_tokens: options.overrides?.max_token,
//         stop: options.overrides?.stop
//         semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory,
//                 presence_penalty: options.overrides?.presence_penalty,
//                 frequency_penalty: options.overrides?.frequency_penalty,
//                 top_p: options.overrides?.top_p,
//                 max_tokens: options.overrides?.max_token,
//                 stop: options.overrides?.stop
//         semantic_ranker: options.overrides?.semanticRanker,
//         semantic_captions: options.overrides?.semanticCaptions,
//         top: options.overrides?.top,
//         temperature: options.overrides?.temperature,
//         prompt_template: options.overrides?.promptTemplate,
//         prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//         prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//         exclude_category: options.overrides?.excludeCategory,
//         suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
//         semantic_ranker: options.overrides?.semanticRanker,
//                 semantic_captions: options.overrides?.semanticCaptions,
//                 top: options.overrides?.top,
//                 temperature: options.overrides?.temperature,
//                 prompt_template: options.overrides?.promptTemplate,
//                 prompt_template_prefix: options.overrides?.promptTemplatePrefix,
//                 prompt_template_suffix: options.overrides?.promptTemplateSuffix,
//                 exclude_category: options.overrides?.excludeCategory,
//                 presence_penalty: options.overrides?.presence_penalty,
//                 frequency_penalty: options.overrides?.frequency_penalty,
//                 top_p: options.overrides?.top_p,
//                 max_tokens: options.overrides?.max_token,
//                 stop: options.overrides?.stop
//     },
//     talk: {
//                 oaModel: options.talk?.oaModel,
//                 cogIndex: options.talk?.cogIndex,
//                 storage: options.talk?.storage,
//                 fileName: options.talk?.fileName,
//                 conversationStyle: options.talk?.conversationStyle
//             },
// };
