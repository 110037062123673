import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownMenuItemType, IDropdownOption } from "@fluentui/react";
import { Margin, usePDF } from "react-to-pdf";
import Cookies from "js-cookie";

import { MenuContext } from "./MenuContext";
import { MenuItemType, UserMenuApi, getChatName } from "./api";
import { checkUserLoggedIn } from "./utils";
import { menus } from "./assets/examplesData";
type Props = {
    children: React.ReactNode;
};

// MenuProvider is a context provider that provides the selected menu item, a function to set the selected menu item, a function to generate a PDF, a reference to the target element, and a flag to indicate whether the answers are being displayed.
export function MenuProvider({ children }: Props) {
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>("GEA Chat");
    const [isContinuing, setIsContinuing] = useState<boolean>(false);
    const [isAnswers, setIsAnswers] = useState<boolean>(false);
    const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);
    const [formattedUserMenus, setFormattedUserMenus] = useState<IDropdownOption<any>[]>([]);
    const [isMenuLoading, setIsMenuLoading] = useState<boolean>(false);
    const [isAuthorizedUser, setIsAuthorizedUser] = useState<boolean>(false);
    const [authorizedPath, setAuthorizedPath] = useState<string>("");

    const [user, setUser] = useState<string>("");
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { chat_session_id, name } = useParams();
    const { toPDF, targetRef } = usePDF({
        method: "save",
        filename: getChatName(pathname, chat_session_id || ""),
        page: { margin: Margin.MEDIUM }
    });
    let isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    if (isLocalhost) {
        Cookies.set("isUserLoggedIn", "True");
    }

    const checkUserLoggedIn = () => {
        const isUserLoggedIn = Cookies.get("isUserLoggedIn");
        if (isUserLoggedIn == "False" || isUserLoggedIn == undefined) {
            window.location.replace(`${location.origin}/#/login`);
        }
    };

    const makeApiRequest = async () => {
        try {
            // If the application is not running on localhost and the user menus are empty, set isMenuLoading to true
            if (!isLocalhost && menuItems.length === 0) {
                setIsMenuLoading(true);
            }
            let result = await UserMenuApi();
            if (result.error) {
                return;
            }
            if (!isLocalhost && menuItems.length === 0) {
                setMenuItems(result.menus);
                setMenuItems && setMenuItems(result.menus);
                sessionStorage.setItem("sessionMenus", JSON.stringify(result.menus));
            }

            const formattedMenu = result.menus.map(menuItem => {
                const { MenuItemID, MenuItemName, Path } = menuItem;
                const isHeader = Path === "#";

                let t: IDropdownOption = {
                    key: MenuItemID.toString(),
                    text: MenuItemName,
                    ...(isHeader ? { itemType: DropdownMenuItemType.Header } : { data: Path })
                };

                return t;
            });

            //Set formattedUserMenus to new formattedMenu
            setFormattedUserMenus(formattedMenu);
        } catch (e) {
            console.error(e);
        } finally {
            // Set the user threads to the result's threads in reverse order
            setIsMenuLoading(false);
        }
    };

    let uniqueRoleIDsArray: string | number[] = [];

    useEffect(() => {
        if (isLocalhost) {
            setMenuItems(menus);
        }

        if (shouldProcessMenuItems(menuItems, pathname)) {
            const uniqueRoleIDsArray = getUniqueRoleIDs(menuItems);
            storeUniqueRoleIDs(uniqueRoleIDsArray);

            const currentHash = window.location.hash.toLowerCase();
            if (currentHash === "#/" || currentHash.startsWith("#/chat")) {
                setSelectedMenuItem("GEA Chat");
            } else {
                const currentMenu = getCurrentMenu(menuItems, currentHash, pathname);
                setSelectedMenuItem(currentMenu[0]?.MenuItemName || "");
            }
        }
    }, [menuItems, pathname, name, chat_session_id]);

    // Checks if menu items should be processed
    function shouldProcessMenuItems(menuItems: MenuItemType[], pathname: string): boolean {
        const excludedPaths: string[] = ["/login", "/help", "/about", "/logout", "/403", "/404", "/500"];
        return menuItems && menuItems.length > 0 && !excludedPaths.includes(pathname);
    }

    function getUniqueRoleIDs(menuItems: MenuItemType[]): number[] {
        const uniqueRoleIDs: Set<number> = new Set();
        menuItems
            .filter((item: MenuItemType) => item.RoleID !== 5)
            .forEach((item: MenuItemType) => {
                uniqueRoleIDs.add(item.RoleID);
            });
        return Array.from(uniqueRoleIDs);
    }

    // Stores unique role IDs in session storage
    function storeUniqueRoleIDs(uniqueRoleIDsArray: number[]): void {
        if (uniqueRoleIDsArray.length > 0) {
            sessionStorage.setItem("uniqueRoleIDs", JSON.stringify(uniqueRoleIDsArray));
        }
    }

    // Gets the current menu based on the hash and pathname
    function getCurrentMenu(menuItems: MenuItemType[], currentHash: string, pathname: string): MenuItemType[] {
        const urlFromPathname = currentHash.split("/");

        return menuItems
            .filter(menu => menu.Path !== "#" && menu.IsActive)
            .filter(menu => {
                const menuPathLower = menu.Path.toLowerCase();
                const urlPathLower = urlFromPathname[1]?.toLowerCase() || "";

                if (menuPathLower.slice(1) === "") {
                    return (urlPathLower === "" && menuPathLower === "/") || (urlPathLower === "chat" && menuPathLower === "/");
                } else {
                    if (pathname.includes("usecase")) {
                        const splitPath = menuPathLower.split("/");
                        return menuPathLower.startsWith("/")
                            ? urlFromPathname[2]?.toLowerCase() === splitPath[2]?.toLowerCase()
                            : urlFromPathname[2]?.toLowerCase() === splitPath[1]?.toLowerCase();
                    } else if (currentHash.includes("pureplus")) {
                        const removePrefixFromMenuPath = menuPathLower.startsWith("/") ? menuPathLower.slice(1) : menuPathLower;
                        return removePrefixFromMenuPath === "purepluso";
                    }
                    return currentHash.includes(menuPathLower.slice(1));
                }
            });
    }

    // useEffect hook that runs when 'pathname' or 'chat_session_id' changes
    useEffect(() => {
        // Check if the user is logged in by reading a cookie
        const isUserLoggedIn = Cookies.get("isUserLoggedIn");

        // If menuItems is not defined or empty, or the user is not logged in, exit the useEffect
        if (!menuItems || menuItems.length === 0 || isUserLoggedIn !== "True") {
            return;
        }

        // Get the current URL hash and extract the first part after the hash ('#')
        const currentHash = window.location.hash.toLowerCase();
        const urlFromPathname = currentHash.split("/")[1];

        // Function to filter and get the active menu items based on the current path
        const getMenuName = (): MenuItemType[] => {
            return menuItems
                .filter(menu => menu.Path !== "#" && menu.IsActive) // Filter out inactive menu items and those with path '#'
                .filter(menu => {
                    const menuPath = menu.Path.toLowerCase();
                    const menuPathWithoutSlash = menuPath.startsWith("/") ? menuPath.slice(1) : menuPath;

                    // Special case handling for root ("/") and chat ("/chat") paths
                    if (menuPathWithoutSlash === "" || menu.Path === "/chat") {
                        return (
                            (urlFromPathname === "" && menuPath === "/") ||
                            (urlFromPathname === "chat" && menuPath === "/") ||
                            (urlFromPathname === "chat" && menuPath === "/chat")
                        );
                    } else {
                        // Match other paths by checking if the current hash includes the menu path
                        if (currentHash.includes("usecase") && menuPathWithoutSlash.includes("usecase")) {
                            return currentHash.split("/")[2] == menuPathWithoutSlash.split("/")[1];
                        }
                        if (currentHash.includes("pureplus") && menuPathWithoutSlash === "purepluso") {
                            return true;
                        }
                        return currentHash.includes(menuPathWithoutSlash);
                    }
                });
        };

        // Get the filtered menu name
        const menuName = getMenuName();

        // Function to check if the current path is one of the special paths
        const isSpecialPath = (path: string): boolean => {
            const specialPaths = ["/login", "/help", "/about", "/logout", "/403", "/404", "/500"];
            return specialPaths.includes(path);
        };

        // Function to handle unauthorized access by redirecting to the 403 page
        const handleUnauthorizedAccess = () => {
            window.location.replace(`${location.origin}/#/403`);
            setIsAuthorizedUser(false);
            setAuthorizedPath("");
        };

        // If no valid menuName found and the path is not special, handle unauthorized access
        if (!menuName.length && !isSpecialPath(pathname)) {
            handleUnauthorizedAccess();
        } else {
            // Check if the path is the root ("/") or chat ("/chat")
            const isRootOrChatPath = menuName[0]?.Path === "/" || menuName[0]?.Path === "/chat";
            // Check if the path is not allowed (not root, not chat, and not special)
            const isNotAllowedPath = pathname !== "/" && pathname !== "/chat" && !pathname.startsWith("/chat/") && !isSpecialPath(pathname);

            // If the path is root or chat but also not allowed, handle unauthorized access
            if (isRootOrChatPath && isNotAllowedPath) {
                handleUnauthorizedAccess();
            } else {
                // Otherwise, set the user as authorized and update the authorized path
                setIsAuthorizedUser(true);
                setAuthorizedPath(menuName[0]?.MenuItemName || "");
            }
        }

        // Log the final menuName and authorization status
    }, [pathname, chat_session_id]);

    useEffect(() => {
        if (isLocalhost) {
            Cookies.set("isUserLoggedIn", "True");
        }
        if (sessionStorage.getItem("sessionMenus")) {
            sessionStorage.removeItem("sessionMenus");
        }
        checkUserLoggedIn();
        const isUserLoggedIn = Cookies.get("isUserLoggedIn");
        if (isUserLoggedIn === "False" || isUserLoggedIn === undefined) {
            window.location.replace(`${location.origin}/#/login`);
        } else if (!isLocalhost && menuItems.length === 0 && isUserLoggedIn === "True") {
            makeApiRequest();
        }

        // Cleanup function
        return () => {
            window.removeEventListener("load", checkUserLoggedIn);
        };
    }, []);

    const value = {
        selectedMenuItem,
        setSelectedMenuItem,
        toPDF,
        targetRef,
        isAnswers,
        setIsAnswers,
        isContinuing,
        setIsContinuing,
        menuItems,
        setMenuItems,
        user,
        setUser,
        formattedUserMenus,
        // isAuthorizedUser,
        authorizedPath,
        isMenuLoading
    };

    return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}
